<template>
  <div class="quality-detail-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>质检中心</Breadcrumb-item>
        <Breadcrumb-item>抽查记录</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="card-container">
        <Card>
          <p slot="title">基本信息</p>
          <div class="card-box clearfix">
            <Row>
              <Col :lg="{span: 2}" :md="{span: 4}" class="tac">
                <div class="card-avatar">
                  <div class="card-img">
                    <img :src="avater" alt="" width="80px" height="80px">
                  </div>
                  <p class="card-text">头像</p>
                </div>
              </Col>
              <Col :lg="{span: 22}" :md="{span: 20}">
                <div class="card-info">
                  <Form :label-width="85">
                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>身份证号：{{id_card}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>姓名：{{name}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>性别：{{sex | sex}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>民族：{{nation}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>生日：{{birthday}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>年龄：{{age}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>建档日期：{{create_time}}</p>
                      </Col>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>服务员工：{{staff}}</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col :lg="{span: 6}" :md="{span: 12}">
                        <p>联系电话：{{phone}}</p>
                      </Col>
                      <Col :lg="{span: 15}" :md="{span: 12}">
                        <p style="word-break: break-all">备注：{{remark}}</p>
                      </Col>
                    </Row>
                    
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
      <div class="commom-panel-one">
        <Form class="search-form" :label-width="120">
           <Row>
            <Col span="24">
              <Form-item label="门店服务抽查：">
                <div>{{store_result}}</div>
                <div>{{store_remark}}</div>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
               <Form-item label="方案服务抽查：">
                <div>{{plan_result}}</div>
                <div>{{plan_remark}}</div>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
               <Form-item label="促进服务抽查：">
                <div>{{boost_result}}</div>
                <div>{{boost_remark}}</div>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
               <Form-item label="医生服务抽查：">
                <div>{{doctor_result}}</div>
                <div>{{doctor_remark}}</div>
              </Form-item>
            </Col>
          </Row>
        </Form>
      </div>
      <div class="commom-panel-two">
        <div class="commom-panel-title">问题</div>
        <div class="commom-panel-main" v-for='(item, index) in problemList' :key="index">
          <Form class="search-form" :label-width="111">
            <Row>
              <Col span="24">
                <Form-item label="咨询种类：">
                  {{item.level1 + '/' + item.level2}}
                </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Form-item label="详细情况：">
                  {{item.detail_info}}
                </Form-item>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <Form-item label="是否上报解决：">
                  {{item.should_report}}
                </Form-item>
              </Col>
            </Row>
            <Row v-if="item.should_report === '已解决'">
              <Col span="24">
                <Form-item label="解决方案：">
                  {{item.reslove}}
                </Form-item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div class="submit-box tac">
        <Button type="info" size="large" @click="goBack()">返回</Button>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import qualityService from '@/services/qualityService';
import { sex } from '@/filters/filters';
export default {
	components: {
		'v-title': vTitle,
	},
	filters: {
		sex,
	},
	data() {
		return {
			avater: '',
			id_card: '',
			name: '',
			sex: '',
			nation: '',
			birthday: '',
			age: '',
			create_time: '',
			staff: '',
			remark: '',
			phone: '',
			store_result: '',
			store_remark: '',
			plan_result: '',
			plan_remark: '',
			boost_result: '',
			boost_remark: '',
			doctor_result: '',
			doctor_remark: '',
			memberId: '',
			problemList: [],
		};
	},
	computed: {
		boostId() {
			return this.$route.params.boost_id;
		},
	},
	created() {
		var param = { boost_id: this.boostId };
		qualityService.getRecordDetail(param).then((data) => {
			this.avater = data.head_info.user_info.avater;
			this.remark = data.head_info.user_info.remark;
			this.id_card = data.head_info.user_info.id_card;
			this.name = data.head_info.user_info.name;
			this.sex = data.head_info.user_info.sex;
			this.nation = data.head_info.user_info.nation;
			this.birthday = data.head_info.user_info.birthday;
			this.age = data.head_info.user_info.age;
			this.create_time = data.head_info.user_info.create_time;
			this.staff = data.head_info.user_info.staff;
			this.phone = data.head_info.user_info.phone;
			this.store_result = data.record.store_result;
			this.store_remark = data.record.store_remark;
			this.plan_result = data.record.plan_result;
			this.plan_remark = data.record.plan_remark;
			this.boost_result = data.record.boost_result;
			this.boost_remark = data.record.boost_remark;
			this.doctor_result = data.record.doctor_result;
			this.doctor_remark = data.record.doctor_remark;
			this.memberId = data.head_info.user_info.member_id;
			this.problemList = data.problem_list;
		});
	},
	methods: {
		goBack() {
			this.$router.push({ name: 'qualityList', params: { member_id: this.memberId } });
		},
	},
};
</script>

<style lang="css" scoped>
.quality-detail-container .card-container .ivu-card-head > p {
  color: #fff;
}
.quality-detail-container .card-container .card-box {
  position: relative;
}
.quality-detail-container .card-container .ivu-col {
  margin-bottom: 20px;
}
.quality-detail-container .card-container .card-avatar {
  padding: 30px 0;
}
.quality-detail-container .card-container .card-img img {
  border-radius: 100%;
}
.quality-detail-container .card-container .card-text {
  text-align: center;
  padding-top: 8px;
}
.quality-detail-container .card-container .card-info {
  padding-top: 30px;
}
.quality-detail-container .card-container .ivu-form-item {
  margin-bottom: 0;
}
.quality-detail-container .clicka {
  color: #ff8400;
  text-decoration: underline;
}
.quality-detail-container .submit-box {
  margin-top: 30px;
}
.quality-detail-container .ivu-btn-success,
.quality-detail-container .ivu-btn-info {
  padding: 11px 48px;
  margin: 0 26px;
}
.quality-detail-container .add-problem {
  padding: 10px 30px;
}
.quality-detail-container .add-problem .add-btn {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding-left: 26px;
  background: url(@/assets/img/icon_add.png) no-repeat left;
}
.quality-detail-container .ivu-radio-wrapper {
  margin-right: 30px;
}
</style>
